html, body {
    font-size: 14px;
    color: #151b26;
    background-color: #f6f8f9;
    height: 100%;
    width: 100%;
    display: flex;
}

#root {
    width: 100%;
}

nav .navbar-nav li {
    margin-bottom: 10px;
}

nav .navbar-nav li a.active {
    color: cornflowerblue;
}

nav .navbar-nav li a {
    color: white;
    text-decoration: none;
}

nav .navbar-nav li a:hover {
    color: lightgrey;
}

.nav-icon {
    margin-right: 10px;
    width: 18px;
    height: 18px;
}

.nav-icon-collapsed {
    margin-right: 10px;
    width: 28px;
    height: 28px; 
}

.nav-three-bars {
    margin-left: -7px;
}

.stacked-card {
    margin-top: 15px;
}

.layout {
    height: 100%;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0;
    margin-bottom: 5px;
    background-color: #fff;
    border: 0;
    font-size: small;
}

.has-search {
    position: relative;
}

.has-search .form-control {
    padding: 0 1.7rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 1rem;
    height: 2rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    margin-left: 5px;
}

.has-search .clear-search {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    line-height: 1em;
    color: #aaa;
}

.has-search .clear-search:focus {
    outline: none;
}

.emy-list-header {
    font-size: 12px;
}

.query-match-link {
    padding: 0;
}

.cluster-spinner {
    font-size: 14px;
}

.realtime-matches-spinner {
    font-size: 14px;
    margin-left: 10px;
}

.show-grid {
    padding-bottom: 5px;
    padding-top: 5px;
    border-top: 1px solid #ebebeb;
}

.show-grid-no-line {
    padding-top: 5px;
    padding-bottom: 5px;
}

.check-green {
    color: green;
}

.next-btn {
    float: right;
}

.track-search-spinner {
    font-size: 14px;
    margin-left: 10px;
}

.duplicateRow {
    margin-top: 5px;
}

.not-found {
    margin-top: 5px;
}

.match-modal {
    font-size: small;
}

.text-larger {
    font-size: large;
}

.logo {
    border-bottom: #0c5460 solid 1px;
}

.logo-image {
    max-width: 75px;
}

.code-font {
    font-size: 0.9rem;
    font-family: monospace;
}

.rw-widget-picker {
    min-height: 28px;
}

.rw-input {
    padding-right: 0;
}

.nav-root {
    width: 280px;
    background-color: #151a25;
}

.nav-root-collapsed {
    width: 6rem;
    background-color: #151a25;
}